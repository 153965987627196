<template>
  <div>
    <div class="home">
      <header-search
        :header-search-subtitle="partnerName"
        :header-search-title-html="$t('home:homeLanding.claim')"
        :claimtop="true"
        :multi-headline="true"
        :only-fill-store="true"
        :random-images="headerBackgroundImages"
      />

      <section
        v-if="lastSeenObjectsCount > 0 && userHasInteracted"
        class="bg-medium"
      >
        <last-seen-objects
          v-if="lastSeenObjectsCount > 0 && userHasInteracted"
          :title="$t('home:lastViewedObjects')"
          title-class="h3"
          variant="center"
          limit="6"
        />
      </section>
      <section>
        <content-image-teaser
          :teaser-title="$t('home:topics')"
          :teaser-elements="teaserTopics"
          :teaser-position="'left'"
          teaser-type="collage"
        />
      </section>
    </div>

    <section class="bg-medium">
      <content-link-list
        :class="'popular-countries'"
        :list-title="$t('home:popularCountries')"
        :list-more="$t('home:showAllCountries')"
        :list-elements="favoriteCountriesSorted"
      />
    </section>

    <section class="bg-bright">
      <content-link-list
        :class="'popular-regions'"
        :list-title="$t('home:popularRegions')"
        :list-more="$t('home:allRegions')"
        :list-elements="favoriteRegions"
      />
    </section>

    <portal-target name="header-search-picker-modal" />
  </div>
</template>

<script>
import HeaderSearch from 'components/HeaderSearch.vue';
// import { hydrateWhenVisible } from 'vue-lazy-hydration';
import hydrateOrObserveWhenVisible from 'utils/hydrateOrObserveWhenVisible';
import ContentImageTeaser from 'components/ContentImageTeaser.vue';
import UserApi from 'mixins/UserApi';
import { mapActions } from 'vuex';
import Sentry from 'plugins/sentry.TARGET';

import GtmTools from 'mixins/GtmTools';
import setupData from 'data/components/home';
import scrollUserInteractionSetup from 'mixins/composition/scrollUserInteraction';

const lazyOptions = {
  observerOptions: {
    rootMargin: '500px',
  },
};

export default {
  name: 'Home',
  i18nOptions: { namespaces: ['home', 'countries', 'user', 'locationLinks'] },
  metaInfo() {
    return this.metaData;
  },
  components: {
    HeaderSearch,
    ContentImageTeaser,
    LastSeenObjects: () => import(/* webpackChunkName: "last-seen-objects" */ 'components/LastSeenObjects.vue'),
    ContentLinkList: hydrateOrObserveWhenVisible(() => import(/* webpackChunkName: "lazyhydrate-cll" */ 'components/ContentLinkList.vue'), lazyOptions),
  },
  mixins: [GtmTools, UserApi],
  setup(props, ctx) {
    const {
      favoriteCountries, favoriteRegions, headerBackgroundImages, teaserTopics, trustAwards, teaserBenefits, teaserWide, travelTips,
    } = setupData({ app: ctx.root });
    const { userHasInteracted } = scrollUserInteractionSetup();
    return {
      favoriteCountries,
      favoriteRegions,
      headerBackgroundImages,
      teaserTopics,
      trustAwards,
      teaserBenefits,
      teaserWide,
      travelTips,
      userHasInteracted,
    };
  },
  computed: {
    partnerName() {
      return this.$store.getters.getPartnerConfigValue('partnerName') || '';
    },
    userDataReady() {
      return this.$store.getters.userDataStatus === 'userSaved';
    },
    favoriteCountriesSorted() {
      const deUmlaute = {
        Ä: 'AE', Ü: 'UE', Ö: 'OE', ß: 'SS',
      };
      const countries = [...this.favoriteCountries].sort((a, b) => {
        const nameA = a.title.toUpperCase().replace(/[ÄÜÖß]/g, (m) => deUmlaute[m]); // ignore upper and lowercase
        const nameB = b.title.toUpperCase().replace(/[ÄÜÖß]/g, (m) => deUmlaute[m]); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      return countries;
    },
    metaData() {
      return {
        // if no subcomponents specify a metaInfo.title, this title will be used
        // title: this.$t('home:metaTitle'),
        meta: [
          { vmid: 'description', name: 'description', content: this.$t('home:descriptionContent') },
          { vmid: 'keywords', name: 'keywords', content: this.$t('home:keywordsContent') },
          {
            vmid: 'robots', name: 'robots', content: 'follow,index',
          },
        ]
      };
    },
    lastSeenObjectsCount() {
      return this.$store.getters.getLastSeenObjects.length;
    },
    showPartnerBanner() {
      return process.env.SHOW_PARTNER_BANNER === 'show';
    },
  },
  watch: {
    // wait for uid from api before login
    userDataReady: {
      handler: 'loginPartner',
    },
  },
  mounted() {
    // reset suggestion value
    this.clearOverflow();
    this.dataLayerReady = true;
  },
  methods: {
    ...mapActions({ partnerLogin: 'PARTNER_LOGIN' }),
    loginPartner() {
      // wait for uid from api before login
      if (this.userDataReady) {
        return new Promise((resolve) => {
          const partnerID = this.$store.getters.getPartnerConfigValue('partnerID');
          const loginCreds = this.$route.query?.c;
          if (partnerID && loginCreds && !this.UserApi_jwt) {
            this.partnerLogin({
              loginData: {
                uid: this.UserApi_uid,
                partner: partnerID,
                c: loginCreds,
              },
            }).catch((error) => {
              if (process.env.NODE_ENV === 'production') {
                Sentry.captureException(error);
              } else {
                // eslint-disable-next-line no-console
                console.error(error);
              }

              this.$bvToast.toast(this.$t('user:login.failed.messageError'), {
                title: this.$t('user:login.failed.headline'),
                appendToast: true,
                variant: 'warning',
              });
            }).finally(() => resolve());
          }
        }).finally(() => {
          // always redirect to current route without "c" param
          const currentQuery = { ...this.$router.currentRoute.query };
          if (currentQuery?.c) {
            delete currentQuery.c;
            this.$router.replace({
              path: this.$router.currentRoute.path,
              query: {
                ...currentQuery,
              },
            });
          }
        });
      }

      return Promise.resolve();
    },
    gtmContentGroupPush() {
      if (this.debounce !== false) {
        clearTimeout(this.debounce);
      }
      this.debounce = setTimeout(() => {
        this.dataLayerPush( // gtm dataLayer push based on EDVUE-156
          {
            event: 'contentGroupPush',
            ContentGroupIndex: 1,
            ContentGroup: 'Startseite',
            ContentGroupData: 'Startseite',
          },
        );
        this.debounce = false;
      }, 2000);
    },
    clearOverflow() {
      if (document) {
        const { body } = document;
        if (body) {
          body.classList.remove('vue-lb-open');
          body.style.position = '';
          body.style.left = '';
          body.style.top = '';
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  @mixin teaser_hover {
    #app:not(.isTouchDevice) &:hover {
      transform: rotate(0deg) scale(1.05);
      cursor: pointer;

      @include transition_fast;
    }
  }

  .home {
    background-color: white;
  }

  .celebrate {
    overflow: hidden;
    padding: 1em 0;
    margin-top: 30px;

    @include gradient_ltr(rgb(222, 145, 55), rgb(248, 230, 115));

    background-size: cover;
    background-repeat: no-repeat;

    .title {
      @include font_size_xl;
      @include font_regular;

      color: #fff;
      text-shadow: 1px 1px 2px rgba($color_ci_900, 0.5);
      line-height: 1.2em;
      margin: 0.25em 0;
    }
  }

  /deep/ .teasers-topics {
    @include flexCols($margin_content);

    > a {
      @include transition_fast;

      display: block;
      position: relative;
      background: #fff;
      color: $color_text !important;

      @include flexCol(3, $margin_content);

      @media #{$media_sm_max} {
        @include flexCol(1, $margin_content);
      }

      .img {
        height: 0;
        padding: 0 0 60% 0;
        position: relative;
        overflow: hidden;

        img {
          display: block;
          width: 100%;
          height: auto;
          margin: 0;
        }

        .title {
          @include font_bold;

          color: #fff;

          @include text_shadow_strong;
          @include font_size_xl;

          padding: 0.75em;
          z-index: 3;
          position: absolute;
          top: 0;
          left: 0;
        }
      }

      @include teaser_hover;

      #app:not(.isTouchDevice) &:hover {
        .content {
          color: $color_text !important;
        }
      }

      .content {
        padding: $margin_content $margin_content calc(#{$margin_content}*1.5);
      }

      .btn {
        background: var(--color_ci);
        color: #fff;

        @include font_size_s;
        @include font_bold;

        position: absolute;
        right: 0;
        bottom: 0;
        padding: 0.25em 0.5em 0.3em 0.75em;
        z-index: 3;

        i.icon {
          margin: 0;
        }
      }
    }
  }

  section {
    overflow: hidden;

    /deep/ section {
      overflow: visible;
    }

    &.bg-dark,
    &.bg-medium,
    &.bg-bright {
      padding: 3em 0;
      margin: 0;

      @media #{$media_xs} {
        padding: 2em 0;
      }

      /deep/ h2,
      /deep/ .h3 {
        @include font_bold;
        @include font_size_xl;

        text-align: center;
        margin: 0 0 1.5em;
        margin-top: 0;
      }
    }

    &.bg-bright {
      background: #fff;
    }

    &.bg-medium {
      background: $color_grey_bg;
    }

    &.bg-dark {
      background: $color_grey;
      color: #fff;

      /deep/ h2,
      /deep/ .h3 {
        color: #fff;
      }
    }
  }

  /deep/ .object-last-seen {
    border: none !important;

    .content {
      background: $color_grey_bg;

      .bg-medium &,
      .bg-dark & {
        background: #fff;
      }
    }

    .price {
      position: absolute;
      bottom: 0;
      right: 0;
      background: var(--color_ci);
      width: auto !important;
      padding: 0.25em 0.5em;
      color: #fff;

      @include text_shadow;

      margin: 0 calc(-#{$margin_content}/2) calc(-#{$margin_content}/2) 0;
    }
  }

</style>
