import { ref, computed } from '@vue/composition-api';
import config from 'config/appConfig';
import imageConfig from 'config/images';

const { CDN } = imageConfig;
const headerBackgroundImages = ref(config.heroImages.home);

export default ({ app }) => {
  const {
    teaserBenefits, favoriteTopics, favoriteCountries, favoriteRegions, favoriteOffers, popularFilter,
  } = config.home;

  const teaserTopics = computed(() => {
    const topics = [];
    config.home.teaserTopics.forEach((teaserTopic) => {
      topics.push({
        ...teaserTopic,
        headingType: 'h3',
        headingClass: 'h5',
      });
    });
    return topics;
  });
  const socialMedia = computed(() => {
    const channels = [];
    config.home.socialMedia.forEach((socialMediaChannel) => {
      channels.push({
        ...socialMediaChannel,
        headingType: 'h3',
        headingClass: 'h5',
      });
    });
    return channels;
  });
  const teaserWide = computed(() => [
    {
      img: `${CDN}/assets/categories/vermieter.jpg`,
      href: { name: 'Retailer' },
      title: 'common:textTeaser.bannerTextLandLord',
      headingType: 'h3',
      options: [
        {
          href: { name: 'Retailer' },
          html: 'home:more',
        },
      ],
    },
  ]);
  const trustAwards = computed(() => [
    {
      img: `${CDN}/assets/home/trust/trusted-shops-kundenbewertungen-siegel.png`,
      alt: app.$t('common:ariaLabel.trustedShops'),
      href: 'https://www.trustedshops.de/bewertung/info_XD842B87AB1E1D33A8CFE6AA80A8E8ECD.html',
      ariaLabel: app.$t('common:ariaLabel.trustedShops'),
      target: '_blank',
      rel: 'noopener',
    },
    {
      img: `${CDN}/assets/home/trust/zerti_silber_new.png`,
      alt: app.$t('common:ariaLabel.eKomi'),
      href: 'https://www.ekomi.de/bewertungen-e-domizilde.html',
      target: '_blank',
      rel: 'noopener',
      ariaLabel: app.$t('common:ariaLabel.eKomi'),
    },
    {
      img: `${CDN}/assets/home/trust/google-bewertung.png`,
      alt: app.$t('common:ariaLabel.googleReviews'),
      href: 'https://search.google.com/local/reviews?placeid=ChIJcW7w0KoOvUcR7Jix0B9dhQ8',
      target: '_blank',
      rel: 'noopener',
      ariaLabel: app.$t('common:ariaLabel.googleReviews'),
    },
  ]);

  const trustMember = computed(() => [
    {
      img: `${CDN}/assets/home/trust/drv_200h.png`,
      style: 'height:50px;min-height:50px;max-height:50px;',
      alt: app.$t('common:imgAlts.altDrv'),
      href: 'https://www.drv.de/',
      target: '_blank',
      rel: 'noopener',
      ariaLabel: 'common:ariaLabel.drvLogo',
    },
    {
      img: `${CDN}/assets/home/trust/deutscher-ferienhausverband_200h.png`,
      style: 'height:50px;min-height:50px;max-height:50px;',
      alt: app.$t('common:imgAlts.altDfv'),
      href: 'https://www.deutscher-ferienhausverband.de/zertifikat/e-domizil/',
      target: '_blank',
      rel: 'noopener',
      ariaLabel: app.$t('common:ariaLabel.dfvLogo'),
    },
    {
      img: `${CDN}/assets/home/trust/deutscher-tourismusverband_200h.png`,
      style: 'height:50px;min-height:50px;max-height:50px;',
      alt: app.$t('common:imgAlts.altDtv'),
    },
  ]);

  return {
    favoriteTopics,
    favoriteCountries,
    favoriteRegions,
    headerBackgroundImages,
    trustAwards,
    trustMember,
    teaserBenefits,
    teaserTopics,
    teaserWide,
    favoriteOffers,
    popularFilter,
    socialMedia
  };
};
